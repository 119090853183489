.course-detail {
    @include flex-only;
    .course-left {
        width: 158px;
    }
    .course-image {
        img {
            border-radius: $border-radius-6;
            width: 100%;
            height: 158px;
            object-fit: cover;
        }
    }
    .course-content {
        width: calc(100% - 190px);
        padding-top: 12px;
        @include media(down, $breakpoint-xl){
            width: calc(100% - 180px);
        }
    }
}
.upload-video-photo{
    ul{
        display: flex;
        flex-wrap: wrap;
        li{
            position: relative;
            margin-right: size(12);
            margin-bottom: size(10);
            &:last-child{
                margin-right: 0;
            }
            img{
                width: 96px;
                height: 96px;
                border-radius: $border-radius;
                object-fit: cover;
            }
            .btn-cancel{
                padding: 2px;
                color: $primary;
                background-color: $white;
                width: 15px;
                height: 15px;
                position: absolute;
                right: 0px;
                top: 0px;
                line-height: 6px;
                border-radius: $border-radius;
                display: none;
            }
            &:hover{
              .btn-cancel{
                display: block;            
              }
            }
            .play-btn{
                position: absolute;
                top: 50%;
                left:50%;
                transform: translate(-50%, -50%);
                color: $white;
                background-color: $dark;
                font-size: size(24);
            }
        }
    }
}
.qr-main {
    margin-top: 30px;
}
.qr-code {    
    border: solid 1px $border-color;
    border-radius: $border-radius-6;
    padding: size(2);
    margin-bottom: size(10);
}
.qr-buttons {
    text-align: center;
}

.detail-title {
    margin-bottom: size(22);
}
.detail-sub-title {
    color: $secondary;
    margin-bottom: size(10);
}
.comman-ul {
    padding-left: size(30);
    font-size: size(18);
    margin-bottom: size(24);
    @include media(down, $breakpoint-xl){
        padding-left: size(20);
        font-size: size(16);
        margin-bottom: size(16);
    }
    > li {
        position: relative;
        line-height: 1.35;
        padding-left: size(18);
        margin-bottom: size(2);
        &::before {
            content: "";
            width: 7px;
            height: 7px;
            border-radius: 100%;
            background-color: $dark;
            display: inline-block;
            top: 8px;
            left: 0;
            position: absolute;
        }
    }
}
.type-number {
    padding-left: size(40);
    margin-top: 3px;
    margin-bottom: size(15);
    @include media(down, $breakpoint-xl){
        padding-left: size(30);
    }
    li {
        list-style-type: decimal;
        margin-bottom: 3px;
        &::marker {
            font-weight: $font-bold;
        }
    }
}

.no-module {
    text-align: center;
    .no-module-icon {
        width: size(80);
        height: size(80);
        background-color: $white;
        box-shadow: $box-shadow-md;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: size(20);
        border-radius: $border-radius-6;
    }    
    .bx-folder {
        font-size: size(20);
        width: size(40);
        height: size(40);
        background-color: $primary;
        border-radius: $border-radius-6;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
    }
}
.module-buttons {
    .btn {
        margin-right: size(18);
        &:last-child {
            margin-right: 0;
        }
    }
}

.modules-box {
    .img-box {
        width: 100%;
        img {
            width: 100%;
            border-radius: $border-radius-6;
            height: 200px;
            object-fit: cover;
            @media (max-width: 1599.9px) {
                height: 154px;
            }
            @include media(down, $breakpoint-xxl){
                height: 126px;
            }
            @include media(down, $breakpoint-xl){
                height: 154px;
            }
        }
    }
    .content-box {
        margin-top: size(10);
    }
    .course-action-badge {
        @include flex-between;
    }
    .course-description {
        border: none;
        padding: 0;
        margin-top: size(8);
        .btn {
            font-size: size(11);
            padding: size(2) size(20);
        }
    }
}

/* Add Module / Paste Module */
.add-module {
    @include flex-between;
    cursor: pointer;
    position: relative;
    padding-right: size(26);
    &::after {
        content: "\e9b9";
        font-size: size(24);
        border: 0;
        font-family: $font-boxicon;
        position: absolute;
        right: 0;
        top: size(10);
        color: $dark;
    }
    .add-module-img {
        width: 50px;
        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: $border-radius-6;
        }
    }
    .add-module-name {
        width: calc(100% - 62px);
        font-size: size(16);
    }    
}
.add-module-content {
    padding: size(14) size(20) size(6) size(62);
    display: none;    
    .custom-checkbox {
        label {
            margin-bottom: size(18);
        }
        &:last-child {
            label {
                margin-bottom: 0;
            }
        }
    }
}
.add-module-row {
    padding: size(18) size(40);
    border-bottom: solid 1px $border-color-light;
    @include media(down, $breakpoint-xl){
        padding: size(14) size(26);
    }
    &:last-child {
        border-bottom: 0;
    }
    &.open {
        .add-module-content {
            display: block;
        }
        .add-module {
            &::after {
                transform: rotate(-180deg);
            }
        }
    }
    &.custom-radio {
        label {
            display: block;
            margin: 0;
            padding-left: 32px;
        }
        .add-module {
            padding: 0;
            &::after {
                display: none;
            }
        }
        input[type=radio] {
            &:checked {
                ~ span {
                    border-color: $primary;
                    &::after {
                        width: size(10);
                        height: size(10);
                        top: size(6);
                        left: size(6);
                    }
                }
            }
            + span {    
                width: size(16);
                height: size(16);   
                top: 50%;
                transform: translate(0, -50%);
                -webkit-transform: translate(0, -50%);
            }
        }
    }    
}