.login-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  overflow: overlay;
  overflow-x: hidden;
  background-image: url(../../assets/images/login-bg.jpg);
  .login-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (1.75rem * 2));
    margin: 1.75rem auto;
    .login-container {
      background: $white;
      max-width: 540px;
      width: 100%;
      margin: 0 auto;
      padding: size(50);
      border-radius: $border-radius;
    }
    .logo-box {
      text-align: center;
    }
    .login-content {
      text-align: center;
      margin: size(45) size(0) size(30) size(0);
    }
    .forgot-password {
      text-align: center;
      margin-top: size(22);
    }
    .login-bottom {
      text-align: center;
      border-top: 1px solid $border-color;
      margin-top: size(50);
      padding-top: size(36);
      p {
        font-size: size(15);
        margin-bottom: size(22);
      }
    }
    .custom-checkbox {
      label {
        font-size: size(15);
      }
    }
  }
}
// 404
.notfound-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -250px;
  height: 80vh;
  @include media(down, $breakpoint-xl) {
    margin-left: 0;
  }
  .found-box {
    text-align: center;
  }
}
