.course-listing-section{
    h2{
        margin-bottom: size(20);
    }
    .course-listing-col{
        border-bottom: 1px solid $border-color-light;
        margin-bottom: size(30);
        &:last-child{
            border-bottom: 0;
            margin-bottom: size(0);
        }
    }
}

.courses-box {
    margin-bottom: size(30);
    height: calc(100% - 30px);
    @include Transition(all 0.3s ease-in-out);
    &:hover{
        box-shadow: 0 8px 16px 0 rgba(96,97,112,0.30);
    }
    &.card {
        padding: 0;
    }
}

.course-item{
    padding: size(14);
    @include Transition(all 0.3s ease-in-out);
    .course-info{
        display: flex;
        flex-wrap: wrap;
        .img-box{
            flex: 0 0 auto;
            width: 92px;
            @include media(down, $breakpoint-xxl){
                width: 80px;
            }
            img{
                width: 92px;
                height: 92px;
                object-fit: cover;
                border-radius: $border-radius;
                @include media(down, $breakpoint-xxl){
                    width: 80px;
                    height: 80px;
                }
            }
        }
        .content-box{
            width: calc(100% - 92px);
            padding-left: size(14);
            @include media(down, $breakpoint-xxl){
                width: calc(100% - 80px);
            }
        }
        .course-status{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            margin-bottom: size(5);
            .link-course{
                p{
                    font-size: size(10);
                    line-height: size(14);
                    letter-spacing: size(0.1);
                    font-weight: $font-bold;
                    color: $gray;
                    margin-bottom: 0;
                }
                a{
                    color: $primary;
                    .bx{
                        top: 1px;
                        position: relative;
                        left: -2px;
                    }
                }
            }
            .badge-outer{
                margin-left: auto;
            }
        }        
    }
    .action-box{
        margin-top: size(5);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .lessons-box{
            p{
                font-weight: $font-bold;
                font-size:size(12); 
                line-height:size(18);
                color: $dark;
                margin-bottom: 0;
            }
        }
        .actions-group{
            ul{
                display: flex;
                align-items: center;
                li{
                    margin-left: size(5);
                    a{
                        color: $gray;
                        &:hover{
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
    .course-description{
        border-top: 1px solid $border-color-light;
        padding-top: size(10);
        margin-top: size(14);
        h6 {
            margin-bottom: size(3);
        }
        p{
            color: $gray;
            margin-bottom: 0;
        }
        font {
            font-family: $font-family-base;
        }
        ol, ul {
            padding: 0;
            margin-bottom: 0;
            li {
                br {
                    display: none;
                }
            }
        }
    }
    .plan-renew{
        border-top: 1px solid $border-color-light;
        padding-top: size(10);
        margin-top: size(14);
        .renew-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: size(10);
            .title{
                font-size: size(13);
                line-height: size(18);
                letter-spacing: size(-0.03);
                color: $gray;
                font-weight: $font-regular;
            }
            .date{
                font-size: size(13);
                line-height: size(18);
                letter-spacing: size(-0.03);
                color: $dark;
                font-weight: $font-bold;
            }
        }
        .renew-btn{
            margin-top: size(14);
            text-align: center;
        }
    }
    .comman-ul {
        margin: 0;
        padding: 0;
        li {
            padding: 0;
            font-size: size(16);
            &::before {
                display: none;
            }
        }
    }
    .detail-sub-title {
        font-size: size(16);
        margin-bottom: 0;
    }
}
.course-filter-dropdown{
    .dropdown-toggle::after{
        display: none;
    }
    .dropdown-menu{
        position: absolute;
        min-width: 395px;
        padding: size(16);
        &::before{
            right: 7px;
        }
    }
}