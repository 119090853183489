/* Bootstrap overdies varibles File */
@import "helpers/variable";
/* Default Bootstrap scss File no need to change */
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
/* Custom Files */
@import "helpers/import.scss";
@import "component/_all.scss";

body {
    background: $body-bg;
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $dark;
    font-weight: $font-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    max-width: 100%;
}

/* Layout */
body,
html {
    height: 100%;
}

/* Custom Scroll with CSS only Supported Browser Chrome, Safari and Firefox */
* {
    scrollbar-color: rgba($black, 0.3) transparent;
    scrollbar-width: thin;
    outline: none !important;
}

::-webkit-scrollbar-track {
    box-shadow: none;
    -webkit-box-shadow: none;
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba($black, 0.3);
}


/* Hide Spinner in Input Number - Firefox*/

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}