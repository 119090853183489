.course-detail {
  .content-box {
    p {
      font-size: size(18);
      line-height: size(24);
      b {
        font {
          color: $light-yellow;
          font-size: size(24);
        }
      }
      p {
        span {
          b {
            color: $light-yellow;
            font-size: size(24);
          }
        }
      }
    }
    ul {
      li {
        font-size: size(18);
        line-height: size(24);
        list-style: disc;
        margin-left: size(50);
      }
    }
    ol {
      li {
        font-size: size(18);
        line-height: size(24);
        list-style: unset;
      }
    }
  }
}
.course-detail {
  .upload-video-photo {
    ul {
      li {
        list-style-type: none;
        margin-left: 0;
      }
    }
  }
}
