.user-actions {    
  .btn {
      min-width: 114px;
      margin-left: 16px;
      &.with-icon {
        .bx-plus-circle {
          margin-right: size(16);
        }
      }
  }  
}
.top-filter-btn {
  display: inline-block;
  position: relative;
  margin-right: 14px;
  vertical-align: top;
}
.filter-data-dropdown {
  display: none;
  padding: size(26) size(22);
  box-shadow: $box-shadow;
  margin-bottom: size(30);
  margin-top: size(10);
  position: relative;
  &::before {
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    left: 20px;
    top: -16px;
    border: 8px solid;
    border-color: transparent transparent #D2D2D2 transparent;
  }
  &::after {
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    left: 20px;
    top: -15px;
    border: 8px solid;
    border-color: transparent transparent $white transparent;
  }
  &.open {
    display: block;
  }
}
.filter-btn {
  .btn {
    display: inline-block;
    margin-left: size(16);
  }
}

.datatable-inner {
  position: relative;
}

.settings-button {
  background-color: $white;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  font-size: size(20);
  padding: 12px 10px 0px 10px;
  display: inline-block;
  outline: none !important;
  z-index: 1;
  color: $primary;
}
.custom-options {
  position: absolute;
  right: 0;
  top: 50px;
  background-color: $white;
  box-shadow: $box-shadow-md;
  padding: size(20) size(16) size(6) size(16);
  border-radius: $border-radius-6;
  min-width: size(180);
  z-index: 11;
  height: auto !important;
  transition: height 0s ease !important;
  -webkit-transition: height 0s ease !important;
  display: none !important;
  &.show {
    display: block !important;
  }
  &::before {
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    right: 12px;
    top: -16px;
    border: 8px solid;
    border-color: transparent transparent #D2D2D2 transparent;
  }
  &::after {
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    right: 12px;
    top: -15px;
    border: 8px solid;
    border-color: transparent transparent $white transparent;
  }
  .cp-row {
    margin-bottom: size(16);
    &:last-child, &:empty {
      margin-bottom: 0;
    }     
  }
  .custom-checkbox {
    label {
      margin: 0;
    }
  }
}

.user-listing-filterOptions {
  margin-bottom: 20px;
}
.datatable-main {
  > .card {
    padding: size(28) size(28) size(10) size(28);
  }
  .card-footer {
    margin-top: size(30);
    background-color: transparent;
    padding: 0;
    border: none;
  }
  .table {
    margin-bottom: 0;
    thead {
      th {
        vertical-align: top;
        &:last-child {
          padding-right: size(40);
        }
      }
    }
    .custom-checkbox {
      input[type=checkbox] {
        + span {
          width: size(20);
          height: size(20);
        }
        &:checked {
          ~ span {
            &:after {
              width: size(6);
              height: size(12);
            }
          }
        }
      }
    }
  }  
  .switch {
    width: 34px;
    height: 18px;
    .slider {
      background-color: #eee;
      &:before {
        height: 11px;
        width: 11px;
        left: 4px;
        bottom: 3px;
      }
    }
    input {
      &:checked {
        + .slider {
          &:before {
            transform: translateX(14px);
            -webkit-transform: translateX(14px);
          }
        }
      }
    }
  }
}
table.dataTable {
  thead {
    th, td {
      border-width: 2px;
      border-color: $border-color;
    }
  }
}
.table {
  thead {
    th {
      border-top: none;
      border-color: $border-color;      
    }
  }
  th, td {
    border-color: $border-color;
    vertical-align: middle;
    white-space: nowrap;
    .custom-checkbox {
      label {
        margin-bottom: 0;
        min-height: size(20);
      }
    }
    &:first-child {
      padding-left: size(8);
      .custom-checkbox {
        label {
          margin-right: 0;
        }
      }
    }
  }
  tbody {
    tr {
      &:first-child {
        td {
          border-top: none;
        }
      }
    }
    td {
      border-color: $border-color;
    } 
  }  
}
.table-striped {
  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: transparent;
      }
    }
  }
}
table.dataTable thead th, table.dataTable thead td, .table th, .table td {
  padding: 12px;
}
.thead-title {
  font-weight: $font-regular;
  font-size: size(15);
}
.sort-column {
  width:6px;
  position: relative;
  text-align: center;
  height:9px;
  padding-right: size(2);
  top: 1px;
  .bx {
    color: $primary;
  }
}
.hover-pointer{
  cursor: pointer;
  position: relative;
}
.action-button {
  color: #A1A1A1;
  font-size: size(24);
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  &:hover {
    color: $primary;
  }
}
.dropdown {
  .action-dropdown {
    box-shadow: $box-shadow-md;
    border: none;
    // right: -8px !important;
    // left: auto !important;
    margin-top: 8px;
    &::before {
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0;
      left: 50%;
      transform: translate(-50%,0) !important;
      -webkit-transform: translate(-50%,0) !important;
      top: -16px;
      border: 8px solid;
      border-color: transparent transparent #D2D2D2 transparent;
      background-color: transparent;
    }
    &::after {
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0;
      left: 50%;
      transform: translate(-50%,0) !important;
      -webkit-transform: translate(-50%,0) !important;
      top: -15px;
      border: 8px solid;
      border-color: transparent transparent $white transparent;
    }
    .dropdown-item {
      display: flex;
      align-items: center;
      font-size: size(15);
      padding: size(10) size(16);
      &:active {
        .bx {
          color: $white;
        }  
      }
      .bx {
        color: $primary !important;
        font-size: size(22);
        margin-right: size(8);
      }
    }
  }
}

.name-with-image {
  @include flex-between;
  white-space: normal;
  min-width: 160px;
  .column-name {
    width: calc(100% - 40px);
    word-break: break-word;
  }
}
.dt-icon-with-name{
  display: flex;
  align-items: center;
  .ngs-icon{
    display: flex;
    align-items: center;
  }
  .bx{
    font-size: size(24);
    color: $primary;
  }
  .type-name{
    font-weight: $font-bold;
    margin-left: size(5);
  }
}
.short-name {
  width: size(32);
  height: size(32);
  background-color: $gray-200;
  border-radius: 100%;
  color: $white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: size(14);
  vertical-align: middle;
}
.table-course {
  @include flex-between;
  white-space: normal;
  min-width: 160px;
  .column-name {
    width: calc(100% - 56px);
    @include media(down, $breakpoint-xl){
      width: calc(100% - 46px);
    }
  }
  .table-image {
    width: 46px;
    height: 46px;
    @include media(down, $breakpoint-xl){
      width: 36px;
      height: 36px;
    }
    img {
      border-radius: $border-radius;
      // object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
.custom-pagination {
  .ng-select {
    width: 90px;
    display: inline-block;
    vertical-align: middle;
    .ng-arrow-wrapper {
      padding-right: 12px;
      font-size: size(15);
      .ng-arrow {
        &::before {
          color: $gray-200;
        }
      }
    }
    .ng-select-container {
      min-height: 40px;
      .ng-value-container {
        color: $gray;
        padding-left: 16px;
      }
    }
    &.ng-select-single {
      .ng-select-container {
        height: 40px;        
        .ng-value-container {
          .ng-input {
            top: 8px;
            padding-left: 16px;
            padding-right: 30px;
          }
        }
      }
    }
  }
  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        font-size: size(15);
      }
    }
  }
  .record-count  {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -2px;
  }
}

.pagination {
  .page-item {
    margin: 0 7px;
    &:first-child, &:last-child {
      .page-link {
        border-radius: $border-radius-6;    
      }
    }
    &.pagination-prev {
      .page-link {
        background-color: $primary;
        color: $white;
        border-color: $primary;
        width: auto;
        padding-left: 12px;
        padding-right: 12px;
        &::before {
          content: "\e9af";
          font-family: $font-boxicon;
          position: relative;
          top: 0;
          color: $white;
          font-size:size(20);
          font-weight: $font-regular;
          margin-right: size(8);
        }
      }
      &.disabled {
        cursor: not-allowed;
      }
    }
    &.pagination-next {
      .page-link {
        background-color: $primary;
        color: $white;
        border-color: $primary;
        width: auto;
        padding-left: 12px;
        padding-right: 12px;
        &::after {
          content: "\e9b2";
          font-family: $font-boxicon;
          position: relative;
          top: 0;
          color: $white;
          font-size:size(20);
          font-weight: $font-regular;
          margin-left: size(8);
        }
      }
      &.disabled {
        cursor: not-allowed;
      }
    }
    &.active {
      .page-link {
        background-color: transparent;
        color: $dark;
        border-color: $primary;
      }
    }
    .page-link {
      border-radius: $border-radius-6;
      font-size: size(15);
      font-weight: 600;
      width: 40px;
      height: 40px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $dark;
      border-color: $border-color;
      box-shadow: none;
    }
  }  
}

/* Role Table */
.role-card {
  &.card {
    padding-top: size(20);
    padding-bottom: size(20);
    @include media(down, $breakpoint-xl){
      padding-top: size(14);
      padding-bottom: size(14);
    }
  }
}
.role-table {
  margin-bottom: 0;
  th, td {
    font-size: size(15);
    font-weight: $font-regular;
    .custom-checkbox {
      label {
        text-transform: capitalize;
        font-size: size(15);
        min-height: size(20);
      }
      input[type=checkbox] {
        + span {
          width: size(20);
          height: size(20);
          top: 3px;
        }
      }
    }
  }
}

.bx-check{
  font-weight: bold;
  font-size: 25px;
  color: rgb(6, 128, 6);
}
