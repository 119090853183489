// Dashboard
.dashboard-wrapper {
  .card {
    padding: size(30);
    margin-bottom: size(30);
    border-color: transparent;
    &.remove-pad {
      padding: 0;
    }
  }
  .mr-b-remove {
    @include media(down, $breakpoint-xl) {
      margin-bottom: 0 !important;
    }
  }
  .boost-box {
    background-color: $primary;
    position: relative;
    padding: size(28);
    min-height: 220px;
    height: calc(100% - 30px);
    margin-bottom: 30px;
    border-radius: $border-radius;
    cursor: pointer;
    @include Transition(all 0.3s ease-in-out);
    &:hover {
      box-shadow: $box-shadow-md;
    }
    h3 {
      color: $white;
      max-width: 140px;
    }
    img {
      position: absolute;
      right: -23px;
      top: -22px;
    }
  }
  .product-box {
    padding: size(28);
    position: relative;
    height: calc(100% - 30px);
    margin-bottom: 30px;
    border: 1px solid $border-color;
    overflow: hidden;
    border-radius: $border-radius;
    cursor: pointer;
    @include Transition(all 0.3s ease-in-out);
    @include media(down, $breakpoint-xl) {
      height: auto;
    }
    &:hover {
      box-shadow: $box-shadow-md;
    }
    &.box-height {
      height: 100%;
      margin-bottom: 0;
      @include media(down, $breakpoint-xl) {
        height: auto;
        margin-bottom: 30px;
      }
    }
    .info-box {
      margin-bottom: 0;
      h2 {
        font-size: size(40);
        line-height: size(56);
        letter-spacing: size(-0.88);
        margin: size(10) size(0);
        text-align: center;
      }
      h6 {
        color: $gray;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        .bx {
          font-size: size(22);
        }
        span {
          margin: size(0) size(6) size(0) size(4);
        }
        &.up-graph {
          .bx {
            color: $secondary;
          }
          span {
            color: $secondary;
          }
        }
        &.down-graph {
          .bx {
            color: $primary;
          }
          span {
            color: $primary;
          }
        }
      }
    }
    .img-block {
      position: absolute;
      right: -95px;
      bottom: -32px;
      .bx {
        font-size: size(250);
      }
    }
  }
  .icon-title {
    // display: flex;
    // align-items: center;
    .icon-box {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      border-radius: $rounded-pill;
      .bx {
        font-size: size(20);
      }
    }
  }
  .title-box {
    text-align: center;
    p {
      margin-bottom: 0;
      font-size: size(12);
      line-height: size(18);
      color: $gray;
    }
    &.box-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    h5 {
      font-size: size(12);
      text-transform: uppercase;
      color: $gray;
    }
  }
  .bg-none {
    background: transparent !important;
  }
  .primary-bg {
    background: rgba($primary, 5%);
    .icon-box {
      background: rgba($primary, 10%);
    }
    .bx {
      color: $primary;
    }
    .img-block {
      .bx {
        color: rgba($primary, 20%);
      }
    }
  }
  .secondary-bg {
    background: rgba($secondary, 5%);
    .icon-box {
      background: rgba($secondary, 10%);
    }
    .bx {
      color: $secondary;
    }
    .img-block {
      .bx {
        color: rgba($secondary, 20%);
      }
    }
  }
  .info-bg {
    background: rgba($info, 5%);
    .icon-box {
      background: rgba($info, 10%);
    }
    .bx {
      color: $info;
    }
    .img-block {
      .bx {
        color: rgba($info, 20%);
      }
    }
  }
  .success-bg {
    background: rgba($success, 5%);
    .icon-box {
      background: rgba($success, 10%);
    }
    .bx {
      color: $success;
    }
    .img-block {
      .bx {
        color: rgba($success, 20%);
      }
    }
  }
  .warning-bg {
    background: rgba($warning, 5%);
    .icon-box {
      background: rgba($warning, 10%);
    }
    .bx {
      color: $warning;
    }
    .img-block {
      .bx {
        color: rgba($warning, 20%);
      }
    }
  }
  .purple-bg {
    background: rgba($purple, 5%);
    .icon-box {
      background: rgba($purple, 10%);
    }
    .bx {
      color: $purple;
    }
    .img-block {
      .bx {
        color: rgba($purple, 20%);
      }
    }
  }
  .pink-bg {
    background: rgba($pink, 5%);
    .icon-box {
      background: rgba($pink, 10%);
    }
    .bx {
      color: $pink;
    }
    .img-block {
      .bx {
        color: rgba($pink, 20%);
      }
    }
  }
  .chocolate-bg {
    background: rgba($chocolate, 5%);
    .icon-box {
      background: rgba($chocolate, 10%);
    }
    .bx {
      color: $chocolate;
    }
    .img-block {
      .bx {
        color: rgba($chocolate, 20%);
      }
    }
  }
  .danger-bg {
    background: rgba($danger, 5%);
    .icon-box {
      background: rgba($danger, 10%);
    }
    .bx {
      color: $danger;
    }
    .img-block {
      .bx {
        color: rgba($danger, 20%);
      }
    }
  }
  .recent-box {
    @include media(down, $breakpoint-xl) {
      margin-bottom: 30px;
    }
    ul {
      margin-top: size(6);
      margin-left: size(50);
      li {
        a {
          font-size: size(15);
          color: $dark;
          font-weight: $font-bold;
          letter-spacing: size(-0.12);
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: size(15) size(0);
          border-bottom: 1px solid $border-color-light;
          .bx {
            font-size: size(24);
            color: $gray;
            margin-left: size(8);
          }
          &:hover {
            color: $primary;
            .bx {
              color: $primary;
            }
          }
        }
      }
    }
  }
  .my-account-box {
    text-align: center;
    margin-bottom: size(30);
    .profile-img {
      background-repeat: no-repeat;
      width: 202px;
      margin: 0 auto;
      padding-top: size(30);
      background-position: 0px 4px;
      img {
        width: 162px;
        height: 162px;
        object-fit: cover;
        border: 2px solid $primary;
        padding: size(15);
        margin-left: -8px;
        border-radius: $rounded-pill;
      }
    }
    h4 {
      margin: size(12) size(0);
    }
  }
  .product-view-box {
    margin-bottom: size(30);
    padding: size(15) size(20);
    border-radius: $border-radius-12;
    cursor: pointer;
    @include Transition(all 0.3s ease-in-out);
    &:hover {
      box-shadow: $box-shadow-md;
    }
    .right-arrow {
      font-size: size(24);
      margin-left: size(8);
    }
  }
  .current-year-select {
    margin-top: size(10);
    margin-left: size(55);
  }
  .monthly-data-box {
    .title-flex-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $border-color-light;
      padding-bottom: size(20);
      margin-bottom: size(20);
    }
  }
  .my-account {
    .comman-tabs .tab-pane {
      padding: size(30) 0;
    }
  }
}
/* page not found */
.notfound-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -250px;
  height: 80vh;
  @include media(down, $breakpoint-xl) {
    margin-left: 0;
  }
  .found-box {
    text-align: center;
  }
}
