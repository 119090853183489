.breadcrumb{
    background: none;
    padding:10px 0 20px;
    margin-bottom: 0;
    @include media(down, $breakpoint-xl){
        padding-bottom: 16px;
    }
    ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            display: inline-block;
        }
    }
    .breadcrumb-item  {
        font-size:1rem;
        color: $gray;
        font-weight: $font-bold;
        + .breadcrumb-item {
            padding-left: size(6);
            &::before {
                content: '\ec8f';
                font-family: 'boxicons', sans-serif;
                position: relative;
                top: 0;
                color: $dark;
                font-size:size(18);
                font-weight: $font-regular;
            }
        }
        &:focus{
            outline: none;
        }
        &.active{
            color: $gray;
            text-transform: capitalize;
        }        
        .bx{
            font-size:size(22);
            position: relative;
            top: -1px;
        }
        a{
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            color: $gray;
            &:hover{
                color: $primary;
            }
        }
    }
}