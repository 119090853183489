@keyframes fadein {
    from {
        -webkit-transform: translate3d(0, 6px, 0);
        transform: translate3d(0, 6px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
.header-section {
    width: 100%;
    background: $white;
    height: 80px;
    border-bottom: 1px solid $border-color-light;
    box-shadow: none;
    z-index: 1051;
    .navbar{
        padding: size(15) size(24);
        height: 80px;
        .navbar-nav{
            justify-content: center;
            align-items: center;
            .nav-link{
                padding: size(0) size(16);
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
            }
            .user-dropdown{
                .dropdown-menu-right{
                    right: -15px !important;
                }
            }
        }
        .profile-header {
            height: 32px;
            width: 32px;
            border-radius: 50%;
            overflow: hidden;
            img {
                object-fit: cover;
                width: 100%;
            }
        }
        .dropdown-user-link {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
        }
    }
}

.dropdown-notification {
    .dropdown-menu{
        padding:size(20);
        width: 24rem;
        &:before{
            right: 15px;
        }
    }
    .dropdown-notification-box{
        .notification-section{
            max-height: 556px;
            position: relative;
            overflow: auto !important;
            overflow-anchor: none;
            -ms-overflow-style: none;
            touch-action: auto;
            -ms-touch-action: auto;
        }
        .view-btn{
            text-align: center;
            margin-top: size(10);
            a{
                font-size: size(18);
                line-height: size(24);
                color: $primary;
                font-weight: $font-bold;
                letter-spacing: size(-0.25);
            }
        }
    }
    // .dropdown-header{
    //     background-color: $primary;
    //     color: $white;
    //     padding: 10px 15px;
    //     a{
    //         color: $white;
    //     }
    // }
    // .scrollable-container {
    //     &.media-list{
    //         max-height: 21rem;
    //         position: relative;
    //         overflow: auto !important;
    //         overflow-anchor: none;
    //         -ms-overflow-style: none;
    //         touch-action: auto;
    //         -ms-touch-action: auto;
    //     }
    // }
    .nav-link-label {
        position: relative;
        padding: 0 1rem;
        .badge.badge-up {
            position: absolute;
            top: -4px;
            right: 14px;
            color: $white;
            padding: 0.25em 0.4em 0.18rem 0.35rem;
            width: 16px;
            height: 16px;
            line-height: size(10);
            font-weight: $font-bold;
            font-size: size(10);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $rounded-pill;
        }
    }
    .bx-bell {
        font-size: size(24);
        color: $gray;
        vertical-align: middle;
        cursor: pointer;
        -webkit-text-stroke: 0.2px $white;
    }
    // .media{
    //     padding: 10px 15px;
    //     color: $gray;
    //     .media-body{
    //         margin-left: 15px;
    //         padding: 0;
    //         .media-heading{
    //             font-size: 14px;
    //             line-height: 22px;
    //             margin:0;
    //         }
    //     }
    // }

}
.notification-section{
    .notification-header{
        margin-bottom: size(10);
        @include flex-between;
        h6{
            font-size: size(15);
            font-weight: $font-regular;
            line-height: size(22);
            color: $dark;
            letter-spacing: size(-0.12);
        }
        a{
            font-size: size(13);
            font-weight: $font-regular;
            line-height: size(18);
            letter-spacing: size(-0.03);
        }
    }
    .notification-item{
        background: rgba($secondary, 5%);
        border: 1px solid transparent;
        padding: size(11) size(14);
        margin-bottom: size(10);
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        border-radius: $border-radius;
        .icon-box{
            background: rgba($secondary, 20%);
            border: 1px solid transparent;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: size(5);
            border-radius: $border-radius;
            flex: 0 0 auto;
            .bx{
                font-size: size(24);
                color: $secondary;
            }
        }
        .notification-info{
            width: calc(100% - 48px);
            padding-left: size(14);
            h6{
                font-size: size(15);
                font-weight: $font-regular;
                line-height: size(16);
                color: $dark;
                letter-spacing: size(-0.12);
                min-height: 32px;
            }
            p{
                font-size: size(11);
                font-weight: $font-bold;
                line-height: size(16);
                color: $secondary;
                letter-spacing: size(0.1);
                margin-bottom: 0;
                text-transform: uppercase;
            }
        }
        &.read-notification{
            background: $white;
            border: 1px solid $border-color;
            .icon-box{
                background: $white;
                border: 1px solid $border-color;
                .bx{
                    color: $primary;
                }
            }
            .notification-info{
                p{
                    color: $gray;
                }
            }
        }
    }
}

.nav-search {
    .bx-search,
    .bx-x {
        font-size: size(24);
        color: $gray;
        vertical-align: middle;
        cursor: pointer;
        -webkit-text-stroke: 0.2px $white;
    }
    .search-input {
        .search-box{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
            background: $white;
        }

        .search-input-icon,
        .search-input-close,
        .search-box,
        .search-list{
            display: none;
        }
        input {
            width: 0;
            border: none;
            background: none;
            line-height: 16px;
            padding: 1.7rem 3.6rem;
        }

        &.search-open {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
            background: rgba(0, 0, 0, 0.95);
            z-index: 9999;

            .search-input-icon,
            .search-input-close,
            .search-list {
                display: block;

            }
            .search-box{
                display: flex;
            }

            input {
                width: 100%;
                outline: none;
                background: none;
                height: 80px;
                padding: 1.7rem;
            }

            .search-list{
                margin: 16px;
                padding: 10px 0;
                border-radius: 12px;
                background: $white;
                overflow-y: auto;
                max-height: 500px;
                li {
                    a {
                        padding: 10px 20px;
                        color: $dark;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        .bx-error-circle{
                            margin-right:10px;
                        }
                        &:hover{
                            color: $primary;
                        }
                    }
                }
            }
        }
    } 
}
body.body-search-open{
    overflow: hidden;
}

