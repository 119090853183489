/* Begin: Button */
.btn {
  font-family: $font-family-base;
  font-weight: $font-bold;
  font-size: size(15);
  line-height: size(22);
  letter-spacing: size(-0.12);
  color: $white;
  padding: size(8) size(30);
  border-radius: $border-radius;
  @include Transition(all 0.3s ease-in-out);

  &:focus {
    outline: none;
    box-shadow: none !important;
  }

  &.btn-sm {
    font-size: size(14);
    line-height: size(20);
    letter-spacing: size(-0.1);
    padding: size(8) size(10);
  }

  &.btn-xsm {
    font-size: size(13);
    line-height: 1;
    padding: size(5) size(10);

    &.with-icon {
      padding: size(5) size(10);

      .bx {
        font-size: size(16);
        margin-right: 3px;
        margin-top: -5px;
      }
    }
  }

  &.btn-lg {
    font-size: size(20);
    line-height: size(28);
    letter-spacing: size(-0.34);
    padding: size(16) size(30);
  }

  &.btn-link {
    color: $primary;
    text-decoration: none;

    &.btn-link-primary {
      color: $primary;
    }

    &.btn-link-secondary {
      color: $secondary;
    }

    &.btn-link-success {
      color: $success;
    }

    &.btn-link-info {
      color: $info;
    }

    &.btn-link-warning {
      color: $warning;
    }

    &.btn-link-danger {
      color: $danger;
    }
  }

  &.btn-transparent {
    background-color: transparent;

    &.btn-primary {
      color: $primary;
    }

    &.btn-secondary {
      color: $secondary;
    }

    &.btn-success {
      color: $success;
    }

    &.btn-info {
      color: $info;
    }

    &.btn-warning {
      color: $warning;
    }

    &.btn-danger {
      color: $danger;
    }
  }

  &.btn-outline {
    background-color: transparent;
    border-color: $border-color;

    &.btn-primary {
      color: $primary;

      &:hover {
        background-color: $primary;
        color: $white;
        border-color: $primary;
      }
    }

    &.btn-secondary {
      color: $secondary;

      &:hover {
        background-color: $secondary;
        color: $white;
        border-color: $secondary;
      }
    }

    &.btn-success {
      color: $success;

      &:hover {
        background-color: $success;
        color: $white;
        border-color: $success;
      }
    }

    &.btn-info {
      color: $info;

      &:hover {
        background-color: $info;
        color: $white;
        border-color: $info;
      }
    }

    &.btn-warning {
      color: $warning;

      &:hover {
        background-color: $warning;
        color: $white;
        border-color: $warning;
      }
    }

    &.btn-danger {
      color: $danger;

      &:hover {
        background-color: $danger;
        color: $white;
        border-color: $danger;
      }
    }
  }

  &.with-icon {
    padding: size(8) size(10);

    .bx {
      font-size: 20px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 6px;
      margin-top: -4px;
    }
  }

  &.btn-default-filter {
    color: $gray;
    font-size: size(15);
    border: solid 1px $border-color;
    min-width: 170px;
    text-align: left;
    font-weight: $font-regular;
    padding-left: size(16);

    @include media(down, $breakpoint-xl) {
      min-width: 150px;
    }

    &:after {
      content: "\e9ac";
      font-size: size(18);
      border: 0;
      font-family: $font-boxicon;
      position: absolute;
      right: size(12);
      top: size(8);
      color: $gray;
    }
  }

  &.btn-gray {
    background-color: $gray-btn;
    color: $dark;
  }

  &.btn-black {
    background-color: #333333;
    border-color: #333333;
    color: $white;

    &:hover {
      background-color: #212121;
    }
  }
}

.text-link-btn {
  font-family: $font-family-base;
  font-weight: $font-regular;
  font-size: size(15);
  line-height: size(22);
  letter-spacing: size(-0.12);
  text-decoration: none;
  @include Transition(all 0.3s ease-in-out);

  &.text-primary {
    color: $primary;
  }

  &.text-secondary {
    color: $secondary;
  }

  &.text-success {
    color: $success;
  }

  &.text-info {
    color: $info;
  }

  &.text-warning {
    color: $warning;
  }

  &.text-danger {
    color: $danger;
  }
}

.icon-btn {
  padding: size(8);
  font-size: size(18);
  color: $white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: $border-radius;
  @include Transition(all 0.3s ease-in-out);

  &:focus {
    outline: none;
    box-shadow: none !important;
  }
}

/* Form Control */
label {
  font-size: size(15);
  color: $dark;
  font-weight: $font-regular;
  font-family: $font-family-base;
  line-height: size(18);
  letter-spacing: size(-0.03);
  margin-bottom: size(4);
}

.form-control {
  background: $white;
  font-family: $font-family-base;
  font-weight: $font-regular;
  font-size: size(18);
  line-height: size(24);
  color: $dark;
  letter-spacing: size(-0.25);
  padding: size(16) size(24);
  height: size(56);
  border: 1px solid $border-color;
  border-radius: $border-radius;

  @include placeholder {
    color: $gray;
  }

  &:read-only,
  &:disabled {
    background: $read-only;
    color: $gray;
    pointer-events: none;
  }

  &[readonly] {
    background: $read-only;
    color: $gray;
  }

  &.focus,
  &:focus {
    @include placeholder {
      color: $gray;
    }

    box-shadow: none;
    background: $white;
    border-color: $primary;
    color: $dark;
  }

  &.language-select {
    &:read-only {
      color: $dark !important;
    }
  }

  &.textarea-large {
    height: size(170);
  }

  &.secondary-control {
    height: size(42);
    background-color: #f7f7f7;
    @include border-radius(20px);
    border-color: #e3e3e3;
    font-size: size(15);
    padding: 0 size(20);
  }
}

textarea {
  &.form-control {
    &.secondary-control {
      height: size(90);
      resize: none;
      padding: size(10) size(20);
    }
  }
}

.has-error,
.error-block {
  font-size: size(13);
  color: $danger;
  font-weight: $font-regular;
  font-family: $font-family-base;
  line-height: size(18);
  letter-spacing: size(-0.03);
  margin-top: size(4);
}

.has-danger {
  border-color: $danger;

  div.ngx-file-drop__drop-zone {
    border-color: $danger !important;
  }

  .angular-editor {
    .angular-editor-toolbar {
      border-color: $danger !important;
    }

    .angular-editor-wrapper {
      .angular-editor-textarea {
        border-color: $danger !important;
      }
    }
  }

  &.ng-select {
    .ng-select-container {
      border-color: $danger !important;
    }
  }
}

.ng-select {
  &.ng-select-focused:not(.ng-select-opened)> {
    .ng-select-container {
      border-color: $primary;
    }
  }
}

.custom-file-input {
  cursor: pointer;
}

.custom-file {
  &.add {
    .custom-file-input {
      &:lang(en) {
        ~.custom-file-label {
          height: calc(1.25em + 1rem + 6px);
          background: $white;
          border-color: $border-color;

          &:focus {
            outline: none;
          }

          &::after {
            content: "\eb21";
            font-family: $font-boxicon;
            background-color: $primary;
            color: $white;
            height: calc(1.25em + 1.25rem);
            padding: 0.6rem 0.75rem;
          }
        }
      }
    }

    .custom-file-label {
      height: calc(1.25em + 1rem + 6px);
      background: $white;
      border-color: $border-color;
      z-index: 0;

      &:focus {
        outline: none;
      }

      &::after {
        content: "\eb89";
        font-family: $font-boxicon;
        background-color: $primary;
        color: $white;
        height: calc(1.25em + 1.25rem);
        padding: 0.6rem 0.75rem;
      }
    }
  }
}

/*==Custom Radio & Checkbox ==*/

.custom-checkbox {
  &.vertical {
    label {
      display: block;
    }
  }

  &.sm {
    label {
      padding-left: size(24);
      font-size: size(16);
      line-height: 1;

      &.info-label-sm {
        font-weight: $font-bold;
      }
    }

    input[type="checkbox"] {
      +span {
        content: "";
        display: inline-block;
        width: size(16);
        height: size(16);
      }

      &:checked {
        ~span:after {
          margin-left: size(-2);
          margin-top: size(-6);
          width: size(5);
          height: size(9);
        }
      }
    }
  }

  &.rounded-checkbox {
    input[type="checkbox"] {
      display: none;

      +span {
        content: "";
        display: inline-block;
        width: size(18);
        height: size(18);
        position: absolute;
        left: 0;
        background: $white;
        border: 1px solid $border-color;
        border-radius: 50%;
      }

      &:checked {
        +span {
          background: $white;
          @include css3-prefix(transition, all 0.3s);
          border: 1px solid $border-color;

          &.bx-shadow {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          }
        }

        ~span:after {
          top: 50%;
          left: 50%;
          margin-left: size(-4);
          margin-top: size(-4);
          width: size(8);
          height: size(8);
          display: block;
          background: $primary;
          @include border-radius(50%);
          border: none;
        }
      }
    }
  }

  label {
    margin: 0 10px 10px 0;
    padding: 0 0 0 33px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    font-size: size(17);
    line-height: size(24);
    letter-spacing: size(-0.22);
    color: $dark;
    font-weight: $font-regular;
    vertical-align: top;

    &.info-label {
      font-weight: $font-bold;
      font-size: size(18);
      margin-bottom: size(6);

      @include media(down, $breakpoint-xl) {
        font-size: size(17);
      }
    }
  }

  input[type="checkbox"] {
    display: none;

    +span {
      content: "";
      display: inline-block;
      width: size(24);
      height: size(24);
      position: absolute;
      left: 0;
      background: $white;
      border: 1px solid $border-color;
      border-radius: $border-radius;
    }

    &:checked {
      +span {
        border: 1px solid $primary;
        background: $primary;
        @include css3-prefix(transition, all 0.3s);
      }

      ~span:after {
        top: 50%;
        left: 50%;
        margin-left: size(-3);
        margin-top: size(-8);
        width: size(6);
        height: size(12);
        border-width: 0 2px 2px 0 !important;
        display: block;
        border: solid $white;
        @include css3-prefix(transform, rotate(45deg));
      }
    }

    ~span:after {
      display: none;
      position: absolute;
      content: "";
    }

    &:disabled {
      +span {
        opacity: 0.7;
      }
    }
  }
}

.custom-radio {
  &.vertical {
    label {
      display: block;
    }
  }

  &.sqaure-radio {
    input[type="radio"] {
      display: none;

      +span {
        content: "";
        display: inline-block;
        width: size(20);
        height: size(20);
        position: absolute;
        left: 0;
        background: $white;
        border: 1px solid $border-color;
        border-radius: $border-radius;
      }

      &:checked {
        +span {
          border: 1px solid $border-color;
          background: $white;
          @include css3-prefix(transition, all 0.3s);

          &.bx-shadow {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          }
        }

        ~span:after {
          top: 50%;
          left: 50%;
          margin-left: size(-2);
          margin-top: size(-7);
          width: size(5);
          height: size(10);
          border-width: 0 2px 2px 0 !important;
          display: block;
          border: solid $primary;
          @include css3-prefix(transform, rotate(45deg));
          border-radius: 0;
          background: transparent;
        }
      }
    }
  }

  label {
    margin: 0 10px 10px 0;
    padding: 0 0 0 26px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    text-transform: capitalize;
  }

  input[type="radio"] {
    display: none;

    +span {
      content: "";
      display: inline-block;
      width: size(18);
      height: size(18);
      position: absolute;
      left: 0;
      background: $white;
      border: 1px solid $border-color;
      border-radius: 50%;
    }

    ~span:after {
      display: none;
      position: absolute;
      content: "";
    }

    &:disabled {
      +span {
        opacity: 0.7;
      }
    }

    &:checked {
      +span {
        background: $white;
        border: 1px solid $border-color;
        @include css3-prefix(transition, all 0.3s);

        &.bx-shadow {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        }
      }

      ~span:after {
        top: 50%;
        left: 50%;
        margin-left: size(-4);
        margin-top: size(-4);
        width: size(8);
        height: size(8);
        display: block;
        background: $primary;
        @include border-radius(50%);

        .green & {
          background: $success;
        }

        .red & {
          background: $danger;
        }
      }
    }
  }

  &.radio-lg {
    label {
      font-size: size(17);
      padding: size(3) 0 0 size(36);
      margin-bottom: size(16);

      @include media(down, $breakpoint-xl) {
        font-size: size(16);
        padding-top: size(2);
      }
    }

    input[type="radio"] {
      +span {
        content: "";
        display: inline-block;
        width: size(22);
        height: size(22);
        border-width: 2px;
        top: 0;
      }

      &:checked {
        ~span {
          border-color: $primary;

          &:after {
            width: size(14);
            height: size(14);
            left: size(6);
            top: size(6);
          }
        }
      }
    }
  }

  &.with-margin {
    label {
      margin-right: size(170);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.with-margin-sm {
    label {
      margin-right: size(30);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media (-webkit-device-pixel-ratio: 0.90) {
  .custom-radio {
    label {
      * {
        zoom: 1.2;
      }
    }
  }
}

@media (-webkit-device-pixel-ratio: 0.80) {
  .custom-radio {
    label {
      * {
        zoom: 1.2;
      }
    }
  }
}


@media (-webkit-device-pixel-ratio: 0.75) {
  .custom-radio {
    label {
      * {
        zoom: 0.9;
      }
    }
  }
}

.ie8 .custom-checkbox input[type="checkbox"],
.ie8 .custom-radio input[type="radio"] {
  display: inline-block;
}

.ie8 .custom-checkbox span,
.ie8 .custom-radio span {
  display: none;
}

.dropdown {
  .dropdown-menu {
    position: relative;
    top: 100%;
    box-shadow: $box-shadow;
    background: $white;
    border-color: $border-color-light;
    border-radius: $border-radius;
    margin-top: 8px;
    -webkit-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
    animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;

    &::before {
      border-top: 1px solid $border-color-light;
      border-left: 1px solid $border-color-light;
      content: "";
      position: absolute;
      top: -1px;
      right: 1.2rem;
      width: 0.75rem;
      height: 0.75rem;
      display: block;
      background: $white;
      transform: rotate(45deg) translate(-7px);
      z-index: -1;
      box-sizing: border-box;
    }

    .dropdown-item {
      font-weight: $font-regular;
      font-size: size(15);
      letter-spacing: size(-0.12);
      line-height: size(22);
      color: $dark;
      padding: size(10) size(16);
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover,
      &:focus {
        background: transparent;
        color: $primary;
        outline: none;
      }

      &.active {
        background: transparent;
        color: $primary;
      }

      .bx {
        margin-right: 10px;
        color: $primary;
        font-size: size(20);
      }
    }
  }

  .dropdown-toggle {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    &.nav-link {
      &:after {
        display: none;
      }
    }
  }
}

.alert {
  &.badge-success {
    background: $success;
    color: $white;
  }

  &.badge-warning {
    background: $warning;
    color: $white;
  }

  &.badge-danger {
    background: $danger;
    color: $white;
  }
}

/* full circle badge as radio buttn with text on side */
.badge {
  font-size: size(11);
  line-height: size(16);
  letter-spacing: size(0.1);
  font-weight: $font-regular;
  text-transform: uppercase;
  padding: size(1) size(6);
  border-radius: $border-radius;

  &.badge-round {
    border-radius: $border-radius-24;
  }
}

.custom-switch {
  padding-left: 48px;
  min-height: 24px;
  height: 24px;

  .custom-control-input {
    width: 48px;
    height: 24px;
    left: 0px;

    &:checked {
      ~.custom-control-label {
        &::after {
          transform: translateX(24px);
        }
      }
    }
  }

  .custom-control-label {
    cursor: pointer;

    &::before {
      left: -48px;
      width: 48px;
      height: 24px;
      border-radius: 100px;
      top: 0;
      background-color: $gray-200;
      border-color: $gray-200;
    }

    &::after {
      top: calc(3px + 0px);
      left: calc(-48px + 3px);
      width: calc(21px - 3px);
      height: calc(21px - 3px);
      background-color: $white;
      border-radius: 100px;
    }
  }

  &.switch-md {
    padding-left: 34px;
    min-height: 16px;
    height: 16px;

    .custom-control-input {
      width: 34px;
      height: 16px;

      &:checked {
        ~.custom-control-label {
          &::after {
            transform: translateX(18px);
          }
        }
      }
    }

    .custom-control-label {
      &::before {
        left: -34px;
        width: 34px;
        height: 16px;
      }

      &::after {
        top: calc(2px + 0px);
        left: calc(-34px + 2px);
        width: calc(14px - 2px);
        height: calc(14px - 2px);
      }
    }
  }

  &.switch-sm {
    padding-left: 24px;
    min-height: 12px;
    height: 12px;

    .custom-control-input {
      width: 24px;
      height: 12px;

      &:checked {
        ~.custom-control-label {
          &::after {
            transform: translateX(12px);
          }
        }
      }
    }

    .custom-control-label {
      &::before {
        left: -24px;
        width: 24px;
        height: 12px;
      }

      &::after {
        top: calc(2px + 0px);
        left: calc(-24px + 2px);
        width: calc(10px - 2px);
        height: calc(10px - 2px);
      }
    }
  }
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none;
}

.form-group {
  margin-bottom: size(26);
  position: relative;

  .input-group-prepend {
    .custom-select {
      &.nice-select {
        min-width: 4.5rem;
        width: 5.5rem;

        &.open {
          .list {
            width: 100%;
          }
        }
      }
    }

    .btn {
      min-width: 5rem;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      &.btn-outline-secondary {
        color: $dark;
        border-color: $border-color;

        &:hover {
          background: transparent;
        }

        &:focus {
          box-shadow: none;
        }

        &.dropdown-toggle {
          background: transparent;

          &::after {
            content: "\e9ac";
            border: 0;
            font-family: $font-boxicon;
            position: absolute;
            right: size(0);
            top: size(3);
            color: $gray;
          }
        }
      }
    }

    .dropdown-menu {
      min-width: 5rem !important;

      .dropdown-item {
        padding: 0.25rem 0.5rem;
      }
    }
  }

  .field-icon {
    cursor: pointer;
    position: absolute;
    right: size(16);
    top: size(39);
    font-size: size(24);
    color: $gray;

    &:hover {
      color: $primary;
    }
  }

  .field-icon-label {
    position: absolute;
    right: size(24);
    top: size(32);
    font-size: size(20);
    color: $gray;
    cursor: pointer;

    &:hover {
      color: $primary;
    }
  }
}

.with-date {
  position: relative;

  .form-control {
    font-size: size(16);
    padding-left: size(60);
  }

  .bx {
    position: absolute;
    left: size(20);
    top: 50%;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    color: $gray;
    font-size: size(24);
  }
}

.otSc {
  margin: 0;
  margin-right: 16px;
  border: 1px solid;
  padding: 10px 0px;
  font-size: 38px;
  text-align: center;
  width: 100%;
  outline: none;
}

.otSc:last-child {
  margin-right: 0;
}

.otpCont {
  padding: 40px 40px;
}

.selectpicker {
  .btn {
    display: block;
    width: 100%;
    height: calc(1.25em + 1rem + 2px);
    padding: 0.5rem 0.75rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    color: #495057;
    background: $white;
  }
}

/* picker  */

.picker {
  .picker__holder {
    border-radius: 0.267rem;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
    background: $white;
    border-color: $border-color;

    &:focus {
      outline: none;
    }

    .picker__table {
      tr {
        td {
          color: $dark;
        }
      }
    }

    .picker__select--month {
      background: $white;
      color: $dark;
      border-color: $border-color;
    }

    .picker__select--year {
      background: $white;
      color: $dark;
      border-color: $border-color;
    }

    .picker__footer button {
      background: $white;
      color: $dark;
    }

    .picker__day--today {
      background: $primary;
      color: $dark;

      &:hover {
        background: $white;
        color: $dark;
      }
    }

    .picker__day--infocus {
      &:hover {
        background: $white;
        color: $dark;
      }
    }

    .picker__day--outfocus {
      &:hover {
        background: $white;
        color: $dark;
      }
    }

    .picker__day--highlighted {
      background: $primary;
    }

    .picker__button--close {
      &::before {
        content: "\ec4e";
        font-family: $font-boxicon;
      }
    }
  }

  .picker__nav--prev {
    &::before {
      content: "\ea1a";
      font-family: $font-boxicon;
      color: $dark;
    }

    &:hover {
      background: $white;
    }
  }

  .picker__nav--next {
    &::before {
      content: "\ea1d";
      font-family: $font-boxicon;
      color: $dark;
    }

    &:hover {
      background: $white;
    }
  }
}

.has-icon-left {
  .form-control {
    padding-left: 2.5rem;
    padding-right: 1.5rem;
    text-transform: uppercase;
  }

  .form-control-position {
    position: absolute;
    top: size(10);
    left: size(10);
    color: $gray;
  }
}

.has-icon-right {
  .form-control {
    padding-left: 1.5rem;
    padding-right: 2.5rem;
    text-transform: uppercase;
  }

  .form-control-position {
    position: absolute;
    top: size(10);
    right: size(10);
    color: $gray;
  }
}

.bootstrap-select {
  .dropdown-menu {
    li {
      &:hover {
        .dropdown-item {
          background: $light !important;
          color: $dark;

          &.active {
            background: $light !important;
            color: $dark;
          }
        }
      }
    }
  }
}

.pagination {
  .page-link {
    background: $white;
    border-color: $border-color;
  }
}

.page-item.disabled .page-link {
  font-family: $font-boxicon !important;
  background-color: $light;
  color: $dark;
  border: 1px solid $border-color;
}

/* daterangepiker */
.daterangepicker {
  background: $white;
  border-color: $border-color;

  .calendar-table {
    background: $white;
    border-color: transparent;

    .next {
      &:hover {
        background: $white;
      }

      span {
        border-color: $dark;
      }
    }

    .prev {
      &:hover {
        background: $white;
      }

      span {
        border-color: $dark;
      }
    }
  }

  td {
    &.off {
      background: transparent;
      color: $dark !important;
    }

    &.available {
      color: $dark;

      &:hover {
        background: $white;
        color: $dark;
      }
    }

    &.active {
      background: $primary;
      color: $white;
    }
  }

  th {
    color: $dark;
  }

  .drp-buttons {
    border-color: $border-color;

    .drp-selected {
      color: $dark;
    }
  }

  &::after {
    border-bottom: 6px solid $white;
  }

  .calendar-time {
    color: $dark;

    select {
      &.ampmselect {
        background: $white;
        color: $dark;
        border-color: $border-color;
      }

      &.minuteselect {
        background: $white;
        color: $dark;
        border-color: $border-color;
      }

      &.hourselect {
        background: $white;
        color: $dark;
        border-color: $border-color;
      }
    }
  }
}

.dropdown-toggle {
  &::after {
    content: "\e9ac";
    border: 0;
    font-family: $font-boxicon;
    color: $gray;
    vertical-align: top;
  }
}

.custom-dropdown {
  margin: 0 5px;

  &.open {
    >.btn {
      position: relative;

      &:before {
        visibility: visible;
      }
    }

    .custom-dropdown-menu {
      display: block;
    }
  }

  .dropdown-toggle {
    padding: 0.25rem 1.5rem 0.25rem 0.625rem;

    &:after {
      content: "\e9ac";
      border: 0;
      font-family: $font-boxicon;
      position: absolute;
      right: size(8);
      top: size(5);
      color: $dark;
    }
  }

  >.btn {
    position: relative;

    &:before {
      visibility: hidden;
      content: "";
      top: auto;
      border-top: 1px solid $border-color;
      border-left: 1px solid $border-color;
      position: absolute;
      bottom: -24px;
      right: 50%;
      width: 0.75rem;
      height: 0.75rem;
      display: block;
      background-color: $white;
      transform: rotate(45deg) translate(-7px);
      z-index: 1031;
      box-sizing: border-box;
      @include css3-prefix(transition, all 0s cubic-bezier(0.25, 0.8, 0.25, 1))
      /* background: red; */
      ;
    }
  }

  .custom-dropdown-menu {
    width: 100%;
    background: #fff;
    color: $dark;
    top: 3rem !important;
    position: absolute;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    padding: 1rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    -webkit-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
    animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
    border-radius: 5px;
    box-shadow: -7px 8px 16px 0px rgba(55, 70, 95, 0.2);
    background-color: $white;
    // z-index: 1;
    display: none;
  }
}

.colorbox {
  .dropdown-menu {
    position: absolute;
    top: 100% !important;
  }
}

.lung-change {
  .form-control {
    background: transparent !important;
    border: none !important;
    width: auto;
  }
}

.ng-select {
  width: 100%;

  .ng-arrow-wrapper {
    width: auto !important;
    padding-right: 24px;

    .ng-arrow::before {
      content: "\e9ac";
      font-family: "boxicons", sans-serif;
      color: $dark;
      font-size: 24px;
    }

    .ng-arrow {
      width: auto !important;
      border: none;
    }
  }

  .ng-clear-wrapper {
    width: auto !important;
  }

  &.border-none {
    width: auto;
    display: inline-block;

    .ng-select-container {
      border: none !important;
      height: auto !important;
      min-height: 40px !important;
    }

    .ng-value-container {
      padding-left: 0 !important;
      flex: inherit !important;

      .ng-input {
        padding-left: 0 !important;
        padding-right: 0 !important;
        top: 10px !important;
      }
    }

    .ng-dropdown-panel {
      .ng-option {
        padding: 6px 12px;
      }
    }

    .ng-arrow-wrapper {
      padding-right: 0 !important;
      margin-left: size(5) !important;
    }
  }

  &.sm-ng-select {
    @include media(down, $breakpoint-xl) {
      width: 150px;
    }

    .ng-select-container .ng-value-container {
      padding-left: 16px;
    }

    &.ng-select-single .ng-select-container .ng-value-container .ng-input {
      top: 10px;
      padding-left: 16px;
      padding-right: 30px;
    }

    &.ng-select-single .ng-select-container .ng-value-container .ng-input>input {
      font-size: 16px;
    }

    .ng-select-container {
      background: $white;
      border: 1px solid $border-color;
      color: $dark;
      min-height: 40px;
      height: 40px;
      border-radius: $border-radius;
      font-size: 16px !important;
    }

    &.ng-select-single .ng-select-container {
      height: 40px;
    }

    .ng-select-container .ng-value-container .ng-placeholder {
      font-size: 15px;
      line-height: 22px;
      color: $gray;
    }

    .ng-arrow-wrapper {
      padding-right: 12px;
    }

    .ng-arrow-wrapper .ng-arrow::before {
      content: "\e9ac";
      font-family: "boxicons", sans-serif;
      color: $gray;
      font-size: 18px;
    }

    &.ng-select-opened>.ng-select-container .ng-arrow {
      top: 26px;
      border: none;
      transform: rotate(-180deg);
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      padding: 10px 16px;
    }
  }
}

.ng-select.ng-select-opened>.ng-select-container {
  background: $white;
  border: 1px solid $border-color;
  color: $dark;
}

.ng-select .ng-select-container {
  background: $white;
  border: 1px solid $border-color;
  color: $dark;
  min-height: 56px;
  height: 56px;
  border-radius: $border-radius;
}

.ng-select.ng-select-single .ng-select-container {
  height: 56px;
  font-size: 18px;
  box-shadow: none !important;
}

.ng-select .ng-select-container .ng-value-container {
  padding-top: 1px;
  padding-left: 24px;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
  font-size: 18px;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  top: 30px;
  border: none;
  transform: rotate(-180deg);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 16px 24px;
  background-color: $white;
  color: $dark;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: $gray;
  font-weight: $font-regular;
  font-size: size(18);
  line-height: size(24);
  letter-spacing: size(-0.25);
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 18px;
  padding-left: 24px;
  padding-right: 50px;
}

.ng-dropdown-panel {
  background-color: $white;
  border: 1px solid $border-color;
  color: $dark;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: $light-gray;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-left: 24px;
  padding-top: 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 15px;
  padding-bottom: 0;
  padding-left: 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: $primary;
  margin-bottom: 0;
  border-radius: 4px;
  margin-right: 10px;
  color: $white;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 400;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: transparent;
}

.ng-with-image {
  .ng-option {
    display: flex;
    align-items: center;

    + {
      .ng-option {
        border-top: solid 1px $border-color-light;
      }
    }
  }

  .ng-value {
    width: 100%;
  }
}

.ng-value-container {
  .ngs-image {
    width: 40px;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .ngs-title {
    font-size: size(18);
  }
}

.ngs-number {
  color: $primary;
  font-size: size(13);
  font-weight: $font-bold;
  margin-right: size(10);
  display: inline-block;
}

.ngs-image {
  width: 50px;
  display: inline-block;
  margin-right: size(10);

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: $border-radius-6;
  }
}

.ngs-title {
  font-size: size(13);
  display: inline-block;
  vertical-align: middle;
  max-width: calc(100% - 120px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ng-select.dt-status-dropdown {
  .ng-select-container {
    border: none;
    height: auto !important;
    min-height: 30px !important;

    .btn {
      padding: size(0) size(6);
      font-size: size(11);
      line-height: size(16);
      color: $white;
      text-transform: capitalize;
      width: 90px;
    }
  }

  .ng-value-container {
    padding-left: 0 !important;
    flex: inherit !important;

    .ng-input {
      padding-left: 0 !important;
    }
  }

  .ng-dropdown-panel {
    width: auto;

    .ng-dropdown-panel-items {
      .ng-option {
        padding: size(5);

        .btn {
          padding: size(0) size(6);
          font-size: size(11);
          line-height: size(16);
          color: $white;
          text-transform: capitalize;
          width: 90px;
        }
      }
    }
  }
}

.accordion-toggle {
  .btn {
    &:focus {
      box-shadow: none !important;
    }
  }
}

.angular-editor-toolbar {
  background-color: $light !important;
  border: 1px solid $border-color !important;
}

.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button {
  background-color: $white !important;
  border: 1px solid $border-color !important;
  color: $dark;

  &:hover {
    background-color: $light !important;
  }
}

.ae-font .ae-picker-label {
  background-color: $white !important;
  color: $dark !important;
  border: 1px solid $border-color !important;
}

.ae-font .ae-picker-label:before {
  background: $white !important;
}

// .ae-font .ae-picker-label svg{
//     fill: $dark!important;
// }
.ae-font .ae-picker-label svg .ae-stroke {
  stroke: $dark !important;
}

.ae-font .ae-picker-options {
  background-color: $white !important;
}

.ae-font .ae-picker-options .ae-picker-item {
  color: $dark !important;
}

.ae-font.ae-expanded .ae-picker-options {
  border-color: $border-color !important;
}

.ae-font.ae-expanded .ae-picker-label {
  color: $dark !important;
}

.angular-editor .angular-editor-wrapper .angular-editor-textarea {
  border: 1px solid $border-color !important;
}

.ae-font .ae-picker-options .ae-picker-item.focused,
.ae-font .ae-picker-options .ae-picker-item:hover {
  background-color: $light !important;
}

.ae-font .ae-picker-options .ae-picker-item.selected {
  background-color: $light !important;
}

.search-input-wrapper {
  position: relative;
  float: right;

  .bx-search {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    font-size: size(22);
    color: $gray-200;
  }

  input[type="search"],
  input[type="text"] {
    padding: 0 20px 0 46px;
    height: 40px;
    font-size: size(15);
    font-weight: $font-regular;
    min-width: 350px;

    @include media(down, $breakpoint-xl) {
      min-width: 260px;
    }
  }

  .remove-icon {
    cursor: pointer;
    position: absolute;
    right: 6px;
    top: 12px;
    font-size: 16px;
    color: $gray;
  }
}

/* Switch Css */

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 21px;
  margin-bottom: 0;
}

.profile-info .switch {
  height: 33px;
}

/* Hide default HTML checkbox */

.switch input {
  display: none;
}

/* The slider */

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  border: 1px solid $white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input.success:checked+.slider {
  background-color: $primary;
}

.switch input:focus+.slider {
  box-shadow: 0 0 1px $primary;
}

.switch input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */

.switch .slider.round {
  border-radius: 12px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

.img-upload-square {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary;
  width: 39px;
  height: 39px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;

  .bx {
    position: absolute;
    right: 0;
    top: 0;
  }

  &.sm {
    width: 25px;
    height: 25px;

    .bx {
      font-size: 1rem;
    }
  }
}

.custom-select-image {
  width: 100%;
  height: 150px;
  overflow: hidden;
  border-radius: 4px;
  background: $gray;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.tag {
  width: auto;
  height: 32px;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: $primary;
  display: flex;

  .tag-title {
    margin-top: 3px;
  }

  .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: $primary;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }
}

.profile-img-box {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;

  img {
    object-fit: cover;
    width: 100%;
  }
}

.profile-name-box {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: size(12);
  color: $white;
  font-weight: $font-regular;
  line-height: size(16);
  letter-spacing: size(0.1);

  &.primary {
    background-color: $primary;
  }

  &.secondary {
    background-color: $secondary;
  }

  &.success {
    background-color: $success;
  }

  &.info {
    background-color: $info;
  }

  &.warning {
    background-color: $warning;
  }

  &.danger {
    background-color: $danger;
  }

  &.tertiary {
    background-color: $tertiary;
  }
}

/*  Calender Design */
.theme-green {
  .bs-datepicker-head {
    background-color: $primary;
  }

  .bs-datepicker-body {
    table {
      td {
        &.week {
          span {
            color: $primary;
          }
        }

        span.selected {
          background-color: $primary;
        }

        span[class*="select-"] {
          &:after {
            background-color: $primary;
          }
        }
      }

      td.selected {
        span {
          background-color: $primary;
        }
      }

      td[class*="select-"] {
        span {
          &:after {
            background-color: $primary;
          }
        }
      }
    }
  }
}

.bs-datepicker-body {
  table {
    border-spacing: 2px 2px;
    border-collapse: separate;

    th {
      color: rgba($dark, 0.8);
    }

    td.is-highlighted {
      &:not(.disabled) {
        &:not(.selected) {
          span {
            background-color: $primary;
            color: $white;
          }
        }
      }
    }

    td {
      color: rgba($dark, 0.8);

      span.is-highlighted {
        &:not(.disabled) {
          &:not(.selected) {
            background-color: $primary;
            color: $white;
          }
        }
      }
    }
  }
}

.bs-datepicker-container {
  padding: 0;
  border: none;
  box-shadow: none;
}

.bs-datepicker-head {

  button.next,
  button.previous {
    span {
      top: -2px;
    }
  }
}

/* Time Picker */
.publish-radio {
  margin-top: size(20);

  .custom-radio {
    &.radio-lg {
      display: inline-block;
      margin-right: size(110);
      vertical-align: top;

      @include media(down, $breakpoint-xl) {
        margin-right: size(20);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .left-spacing {
    padding-left: size(36);
  }
}

.date-time-picker {
  display: inline-block;
  vertical-align: top;
  margin-right: size(20);
  margin-bottom: size(16);

  .date-picker {
    width: 220px;

    @include media(down, $breakpoint-xl) {
      width: 200px;
    }
  }
}

.time-picker {
  tr.text-center {
    display: none;
  }

  .bs-chevron {
    width: 8px;
    height: 8px;
  }

  .bs-timepicker-field {
    width: 50px;
    height: 56px;
    padding: 0;
    font-size: size(16);
  }

  .btn-default {
    background-color: $gray-btn;
    width: 50px;
    height: 56px;
    color: $black;
    padding: 5px;
    font-weight: normal;
    text-align: center;
    font-size: size(16);
  }
}

.owl-dt-calendar-table {
  .owl-dt-calendar-cell {
    color: $dark;
  }

  .owl-dt-calendar-header {
    .owl-dt-calendar-table-divider {
      &:after {
        border-color: $border-color-light;
      }
    }
  }

  .owl-dt-calendar-cell-today {
    &:not(.owl-dt-calendar-cell-selected) {
      border-color: $border-color;
    }
  }

  .owl-dt-calendar-cell-selected {
    background-color: $primary;
  }
}

.owl-dt-timer {
  height: 5em;
  padding: 0px 10px;
}

.owl-dt-timer-content {
  margin: 0;

  .owl-dt-timer-input {
    font-weight: $font-regular;
    font-size: size(16);
    border-color: $border-color;
  }
}

.owl-dt-container-buttons {
  color: $primary;
  height: size(40);

  .owl-dt-container-control-button {

    &:hover,
    &:focus {
      .owl-dt-control-button-content {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
}

.owl-dt-control-period-button {
  &:hover {
    >.owl-dt-control-button-content {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

.duration-main {
  padding-left: size(36);

  .month-picker,
  .custom-time {
    width: 180px;
    display: inline-block;
    margin-right: 18px;
    vertical-align: top;
  }
}

/* File Drop */
div.ngx-file-drop__drop-zone {
  border: dashed 1px $border-color !important;
  border-radius: 6px !important;

  .ngx-file-drop__content {
    text-align: left;
    color: $gray;
    font-size: size(16);
    justify-content: flex-start;
    padding: size(16) size(26);
    position: relative;
    cursor: pointer;

    .bx {
      font-size: size(24);
      color: $gray-200;
      vertical-align: middle;
      margin-right: 6px;
      position: relative;
      top: -2px;
    }

    .ngx-file-drop__file-input {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.ngx-file-sm {
  div.ngx-file-drop__drop-zone {
    min-height: size(56);
    height: auto;

    .ngx-file-drop__content {
      min-height: size(56);
      height: auto;
    }
  }
}

.ngx-file-lg {
  div.ngx-file-drop__drop-zone {
    min-height: size(234);
    height: auto;

    .ngx-file-drop__content {
      min-height: size(234);
      height: auto;
    }
  }
}

/* Swal Modal */
div.swal2-popup {
  .swal2-content {
    color: $dark;
    font-weight: $font-regular;
  }

  .swal2-styled {
    &.swal2-confirm {
      font-size: size(16);
      background-color: $primary;
      border-color: $primary !important;

      &:hover {
        background-color: #ec2f3a;
        border-color: #eb232f !important;
      }
    }

    &.swal2-cancel {
      font-size: size(16);
      background-color: $gray-btn;
      color: $dark;

      &:hover {
        background-color: #e4e4e4;
        background-image: none !important;
      }
    }
  }
}

/* Progress */
.progress {
  height: size(8);
  background-color: #ebebeb;
}

.progress-value {
  font-size: size(11);
}