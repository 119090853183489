.sidebar {
  width: $nav-sidebar-width;
  height: 100%;
  background: $white;
  max-width: $nav-sidebar-width;
  border-right: 1px solid $border-color-light;
  position: fixed;
  left: 0;
  top: 80px;
  z-index: 1031;
  transform: translateX(0);
  @include media(down, $breakpoint-xl) {
    width: 200px;
  }
  .sidebar-inner-wrapper {
    position: relative;
    height: 100%;
    .sidebar-menu {
      padding: 15px;
      height: calc(100% - 80px);
      overflow: auto;
      position: relative;
      .sidebar-menu-list {
        list-style-type: none;
        padding: size(0);
        margin: 0;
        .menu-list-item {
          display: block;
          .bxs-chevron-right {
            color: $gray;
            transition: all 0.3s linear;
          }
          a {
            font-weight: $font-regular;
            font-size: size(16);
            letter-spacing: size(-0.25);
            line-height: size(22);
            color: $dark;
            padding: 15px;
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            align-content: center;
            cursor: pointer;
            @include media(down, $breakpoint-xl) {
              padding: 10px 6px;
              font-size: size(14);
            }
            &.active {
              > .bx {
                &:first-child {
                  color: $secondary;
                }
              }
            }
            .menu-title {
              margin-left: 10px;
            }
            > .bx {
              font-size: size(18);
              color: $primary;
              @include Transition(all 0.3s ease-in-out);
              &.bxs-chevron-right {
                position: absolute;
                right: 15px;
                color: $gray;
                font-size: 14px;
                transform: rotate(90deg);
                @include media(down, $breakpoint-xl) {
                  right: 6px;
                }
              }
              &.bx-right-arrow-alt {
                color: $gray;
                font-size: 14px;
              }
            }
            &:hover {
              > .bx {
                color: $secondary;
                &.bxs-chevron-right {
                  color: $gray;
                }
                &.bx-right-arrow-alt {
                  color: $gray;
                }
              }
            }
          }
          &.child-menu {
            > ul {
              padding-left: 15px;
            }
          }
          &.submenu-open {
            border-radius: 4px;
            background: rgba(153, 153, 153, 5%);
            > a {
              .bx:first-child {
                color: $secondary;
              }
              > .bxs-chevron-right {
                transform: rotate(270deg);
              }
            }
          }
          &.has-child-submenu {
            ul.sidebar-menu-list {
              display: none;
            }
            ul.sidebar-menu-list.show {
              animation: fadein 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s 1
                normal forwards;
              display: block;
              padding: 0;
              a {
                font-size: size(15);
                color: $gray;
                width: auto;
                padding: 15px 0;
                margin: 0 15px;
                border-top: 1px solid $border-color-light;
                &:hover {
                  color: $primary;
                  > .bx {
                    &:first-child {
                      color: $primary;
                    }
                  }
                }
                @include media(down, $breakpoint-xl) {
                  padding: 10px 0;
                  margin: 0 6px;
                  font-size: size(13);
                }
                &.active {
                  .menu-title {
                    color: $primary;
                  }
                  > .bx {
                    &:first-child {
                      color: $primary;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .team-sidebar {
    border-bottom: 1px solid $border-color-light;
    padding-bottom: 20px;
    padding-top: 5px;
    .team-header {
      a {
        font-weight: $font-bold;
        font-size: size(15);
        letter-spacing: size(-0.12);
        line-height: size(22);
        color: $gray;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .bxs-chevron-right {
          position: absolute;
          right: 15px;
          color: $gray;
          font-size: 14px;
          transform: rotate(90deg);
        }
        &.active {
          .bxs-chevron-right {
            transform: rotate(270deg);
          }
        }
      }
    }
    .team-result {
      display: none;
      padding-top: 20px;
      animation: fadein 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s 1 normal
        forwards;
      &.active {
        display: block;
      }
      .team-name {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        img {
          border-radius: $border-radius;
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
        span {
          display: block;
          font-weight: $font-bold;
          font-size: size(16);
          letter-spacing: size(-0.25);
          line-height: size(22);
          color: $dark;
          margin-left: 16px;
        }
      }
      .team-list {
        padding-top: 15px;
        ul {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          li {
            margin-right: 8px;
            margin-bottom: 8px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .add-team {
        padding-top: 15px;
        a {
          font-weight: $font-regular;
          font-size: size(13);
          letter-spacing: size(-0.03);
          line-height: size(18);
          color: $primary;
          display: flex;
          align-items: center;
          .bx {
            font-size: size(16);
            margin-right: 5px;
          }
          &:hover {
            color: darken($primary, 10);
          }
        }
      }
    }
  }
  .upgrade-course {
    background-color: rgba(19, 168, 182, 5%);
    border-radius: $border-radius-32;
    padding: size(24);
    text-align: center;
    position: relative;
    margin-top: 60px;
    margin-bottom: 25px;
    h5 {
      font-weight: $font-regular;
      margin: 20px 0;
      @include media(down, $breakpoint-xl) {
        font-size: size(16);
        line-height: size(22);
        margin: 16px 0;
      }
    }
    .arrow-up {
      background-color: #a3eef5;
      border-radius: $border-radius-16;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      top: -24px;
      .bx {
        color: $dark;
        font-size: size(28);
      }
    }
    .upgrade-btn {
      background-color: #a3eef5;
      font-weight: $font-regular;
      font-size: size(14);
      letter-spacing: size(-0.03);
      line-height: size(18);
      color: $dark;
      padding: size(11) size(52);
      display: inline-block;
      border-radius: $border-radius-24;
      &:hover {
        background-color: $secondary;
        color: $white;
      }
      @include media(down, $breakpoint-xl) {
        padding: size(11) size(20);
      }
    }
  }
}
