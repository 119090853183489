.modal-backdrop {
    z-index: 1100;
}
.modal {
    z-index: 1111;
}
.modal-dialog {
    max-width: 678px;
    @include media(down, $breakpoint-md){
        max-width: 94%;
    }
    &.modal-lg{
        max-width: 775px;
    }
}
.modal-content {
    border: none;
}
.modal-header {
    border: none;
    padding: size(30) size(40) 0 size(40);
    @include media(down, $breakpoint-xl){
        padding: size(20) size(26) 0 size(26);
    }
}
.modal-body {
    padding: size(30) size(40);
    @include media(down, $breakpoint-xl){
        padding: size(20) size(26);
    }
}
.modal-footer {
    border-top: none;
    padding: 0 size(20) size(20) size(20);
    @include media(down, $breakpoint-xl){
        padding: 0 size(16) size(16) size(16);
    }
    .btn {
        margin-left: size(16);
    }
}
.modal-title {
    font-size: size(24);
    font-weight: $font-regular;
    @include media(down, $breakpoint-xl){
        font-size: size(22);
    }
}
.modal-close {
    position: absolute;
    right: size(8);
    top: size(4);
    font-size: size(24);
    color: $dark;
    outline: none !important;
}
.modal-header .close:focus{
    outline: none;
}
body.modal-open .page-wrap{
    -webkit-filter: blur(8px);
    -moz-filter: blur(8px);
    -o-filter: blur(8px);
    -ms-filter: blur(8px);
    filter: blur(8px);
}