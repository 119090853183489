.admin-content-wrapper {
    padding: 100px 30px 40px 280px;

    @include media(down, $breakpoint-xl) {
        padding: 90px 20px 30px 220px;
    }
}

.card {
    border-color: $border-color;
    border-radius: $border-radius-12;
    padding: size(40);

    @include media(down, $breakpoint-xl) {
        padding: size(26);
    }

    &.sm-pad {
        padding: size(14);
    }

    &.md-pad {
        padding: size(24);
    }

    &.remove-pad {
        padding: 0;
    }

    .card-body {
        padding: 0;
    }

    &.inner-padd {
        padding: 0;

        .card-body {
            padding: size(30);

            @include media(down, $breakpoint-xl) {
                padding: size(20);
            }

            +.card-body {
                border-top: solid 1px $border-color-light;
            }
        }
    }
}

.page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: size(20);

    .page-button {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .list-item {
            margin-left: size(14);
        }

        .btn {
            +.btn {
                margin-left: size(14);
            }
        }
    }
}

.bottom-action {
    margin-top: size(20);
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .list-item {
        margin-left: size(14);
    }

    .btn {
        +.btn {
            margin-left: size(14);
        }
    }
}

.color-primary {
    color: $primary;
}

.color-secondary {
    color: $secondary;
}

.color-gray {
    color: $gray;
}

.color-red {
    color: $danger;
}

.status-round {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: $success;
    border: solid 4px #DAF1E3;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    position: relative;
    top: -1px;

    &.red-round {
        background-color: $danger;
        border-color: #FBD8D8;
    }
}

.bx-info-circle {
    font-size: size(18);
    position: relative;
    top: size(2);
    margin-left: size(3);
}

/* Drag and Drop */
.select-modules {
    max-width: size(960);

    .select-row {
        @include flex-between;
    }

    .select-custom-5 {
        width: 44%;
        align-self: flex-start;
    }

    .select-custom-2 {
        width: 12%;
        text-align: center;
    }

    .bx-transfer-alt {
        font-size: size(26);
    }
}

.select-modules-full {
    .select-custom-12 {
        width: 100%;
    }

    .select-custom-2 {
        width: 100%;
        text-align: center;
        margin: size(10) size(0);
    }

    .bx-sort {
        font-size: size(26);
    }
}

/* Angular Editor */
div.angular-editor {
    .angular-editor-toolbar {
        background-color: transparent !important;
        font-family: $font-family-base;
        padding: size(14) size(16) size(12) size(16);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .angular-editor-toolbar-set {
            margin-right: 0 !important;

            .angular-editor-button {
                font-size: size(16);
                border: none !important;
                margin-right: size(4);
                border-radius: $border-radius;

                &:hover {
                    background-color: transparent !important;
                }

                &.active {
                    background-color: rgba($gray, 20%) !important;
                }
            }

            .select-heading {
                width: 100px !important;
            }

            .select-font {
                width: 70px !important;
            }

            .select-font-size {
                width: 46px !important;
            }

            .select-custom-style {
                width: 102px !important;
            }
        }
    }

    pre {
        font-family: $font-family-base !important;
        font-size: size(16);

        code {
            font-family: $font-family-base !important;
            background-color: transparent !important;
            font-size: size(16);
        }
    }

    .ae-font {
        .ae-picker-label {
            font-size: size(15);
            border: none !important;
        }

        .ae-picker-options {
            .ae-picker-item {

                &:hover,
                &.selected {
                    background-color: $border-color-light !important;
                }
            }
        }
    }

    div.angular-editor-wrapper {
        div.angular-editor-textarea {
            margin-top: 0;
            border-top: none !important;
            outline: none !important;
            padding: size(18) !important;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            resize: none;

            font {
                font-family: $font-family-base !important;
            }
        }

        .angular-editor-placeholder {
            padding: size(18) !important;
        }
    }
}

/* Content with Avatar Image */
.content-avatar {
    @include flex-only;

    .avatar-image {
        width: 136px;

        @include media(down, $breakpoint-xl) {
            width: 106px;
        }

        img {
            width: 136px;
            height: 136px;
            border-radius: 100%;
            object-fit: cover;
            border: solid 8px rgba($primary, 20%);

            @include media(down, $breakpoint-xl) {
                width: 106px;
                height: 106px;
                border-width: 5px;
            }
        }
    }

    .inner-content {
        width: calc(100% - 176px);
        padding-top: 16px;

        @include media(down, $breakpoint-xl) {
            width: calc(100% - 136px);
        }

        hr {
            margin-bottom: 40px;

            @include media(down, $breakpoint-xl) {
                margin-bottom: 30px;
                margin-top: 10px;
            }
        }
    }
}

hr {
    &.mb-30 {
        margin-bottom: size(30);
    }

    &.mt-30 {
        margin-top: size(30);
    }

    &.mb-40 {
        margin-bottom: size(40);
    }

    &.mt-40 {
        margin-top: size(40);
    }
}

.change-buttons {
    .btn-sm {
        font-size: size(13);
        padding: size(5) size(16);
        margin-right: size(10);
        min-width: size(86);
    }

    .btn-gray {
        font-weight: $font-regular;
    }
}

/* Breadcrumb with course draft */
.with-draft {
    @include flex-between;
}

.course-draft {
    color: $gray;
    margin: 10px 0 20px 0;

    .custom-switch {
        display: inline-block;
        vertical-align: middle;
        margin-right: size(10);
    }

    .draft-span {
        display: inline-block;
        margin-right: size(1);
    }
}

/* Unlock Sub module section */
.unlock-content {
    margin: size(18) size(0) size(0) size(33);

    .unlock-info {
        background: #DADADA;
        padding: size(20);
        margin-top: size(10);
        border-radius: $border-radius;

        .unlock-group {
            display: flex;
            align-items: center;
            margin-bottom: size(20);

            &:last-child {
                margin-bottom: 0;
            }

            .form-control {
                width: 120px;
                height: 40px;
                padding: size(9) size(16);
                margin-left: size(12);
            }

            label {
                min-width: 158px;

                @include media(down, $breakpoint-xxl) {
                    min-width: 0;
                }

                @include media(down, $breakpoint-xl) {
                    min-width: 158px;
                }
            }

            .icon {
                margin-left: size(12);
            }

            .ng-select.sm-ng-select {
                width: 120px;
                margin-left: size(12);
            }
        }
    }
}


/* Courses */
.assigned-card {
    padding: 0;
    @include flex-only;
    flex-direction: row;
}

.assigned-sidebar {
    width: size(326);
    padding: size(15);

    @include media(down, $breakpoint-xxl) {
        width: size(300);
    }

    @include media(down, $breakpoint-xl) {
        width: size(250);
    }
}

.head-name {
    font-size: size(15);
    font-weight: $font-bold;
    padding: size(12) size(14);
    border-bottom: solid 2px #D6D6D6;
    display: table-cell;

    @include media(down, $breakpoint-xl) {
        padding: size(12) size(10);
    }
}

.pdf-box {
    width: 100%;
    height: 158px;
    border-radius: $border-radius-6;
    background-color: #FCDCDE;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: size(48);
    color: $primary;
}


.sub-module-list-modal {
    .close {
        opacity: 1;
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px;
        margin: 0;
        line-height: 14px;
    }

    .modal-content {
        border-radius: $border-radius-12;
    }

    .modal-header {
        align-items: center;

        .modal-title {
            font-weight: $font-bold;
        }
    }

    .sub-listing {
        flex-wrap: wrap;
        margin: size(0) size(-20);
        max-height: 675px;
        overflow-y: auto;
        overflow-x: hidden;

        @include media(down, $breakpoint-xl) {
            max-height: 550px;
        }

    }
}

.date-not-typable.form-control:read-only {
    background: #fff;
    color: #999999;
    pointer-events: auto !important;
}