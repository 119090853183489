.chart-with-content {
    @include flex-only;
    @include media(down, $breakpoint-xl){
        margin-bottom: size(20);
    }
    .chart-area {
        width: size(260);
        @include media(down, $breakpoint-xxl){
            width: size(220);
            highcharts-chart {
                width: size(220) !important;
                height: size(220) !important;
                > div {
                    width: size(220) !important;
                    height: size(220) !important;
                    > svg {
                        width: size(220) !important;
                        height: size(220) !important;
                    }
                }
            }
        }
        @include media(down, $breakpoint-xl){
            width: size(260);
            highcharts-chart {
                width: size(240) !important;
                height: size(240) !important;
                > div {
                    width: size(240) !important;
                    height: size(240) !important;
                    > svg {
                        width: size(240) !important;
                        height: size(240) !important;
                    }
                }
            }
        }
    }
    .content-area { 
        width: calc(100% - 280px);
        @include media(down, $breakpoint-xxl){
            width: calc(100% - 220px);
        }
        @include media(down, $breakpoint-xl){
            width: calc(100% - 260px);
        }
    }
    h4 {
        font-size: size(22);
        font-weight: $font-regular;
        margin-bottom: size(14);
    }
    .table-responsive {
        max-width: 300px;
    }
    .table {
        thead {
            th {
                border-bottom: solid 2px #D6D6D6;
                @include media(down, $breakpoint-xxl){
                    font-size: size(15);
                }
            }            
        }
        td {
            font-size: size(13);
            font-weight: $font-bold;
            small {
                color: $gray;
                font-size: size(10);
                margin-left: size(10);
            }
        }
    }
    .time-bg {
        width: size(17);
        height: size(17);
        border-radius: $border-radius;
        display: inline-block;
        margin-right: 4px;
        background-color: #EEEEEE;
        vertical-align: middle;
        position: relative;
        top: -1px;
        &.bg1 {
            background-color: #F3C32F;
        }
        &.bg2 {
            background-color: $secondary;
        }
        &.bg3 {
            background-color: $primary;
        }
    }
}
.version-chart {
        .highcharts-color-0 {
            fill: $primary;
        }
        .highcharts-color-1 {
            fill: $secondary;
        }
}
.frequency-chart {
    .highcharts-color-0 {
        fill: #EEEEEE;
    }
    .highcharts-color-1 {
        fill: #F3C32F;
    }
    .highcharts-color-2 {
        fill: $secondary;
    }
    .highcharts-color-3 {
        fill: $primary;
    }
}