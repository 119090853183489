.my-account {
    &.card {
        .card-body {
            padding: size(16) 0 0 0;
        }
    }
}
.comman-tabs {
    .nav-tabs {
        border-color: $border-color;
        .nav-link {
            font-size:size(18);
            color: $dark;
            border: none;
            border-bottom: solid 1px transparent;
            padding: size(20) size(60) size(22) size(60);
            background-color: transparent;
            @include media(down, $breakpoint-xl){
                font-size:size(17);
                padding: size(18) size(30) size(20) size(30);
            }
            &.active {
                color: $primary;
                border-bottom: solid 1px $primary;
            }
        }
    }
    .tab-pane {
        padding: size(40) size(48) size(40) size(48);
        @include media(down, $breakpoint-xl){
            padding: size(30) size(30) size(16) size(30);
        }
    }
    .edit-info {
        margin: size(-20) size(-24) size(16) 0;
        @include media(down, $breakpoint-xl){
            margin: size(-10) size(-10) size(16) 0;
        }
    }
}
.info-box {
    margin-bottom: size(30);
    .bxs-badge-check {
        color: $primary;
        font-size: size(17);
        margin-left: size(3);
        position: relative;
        top: 1px;
    }
}
.info-label {
    font-weight: $font-bold;
    font-size: size(18);
    margin-bottom: size(6);
    @include media(down, $breakpoint-xl){
        font-size: size(17);
    }
}
.info-label-sm {
    font-weight: $font-bold;
    font-size: size(16);
    margin-bottom: size(6);
}
.info-content {
    font-size: size(18);
    @include media(down, $breakpoint-xl){
        font-size: size(17);
    }
}
.account-menu {
    margin: 0;
    padding: size(0) size(28);
    li {
        a {
            border-top: solid 1px $border-color-light;
            line-height: size(78);
            padding: size(0) size(24);
            display: block;
            font-size: size(15);
            font-weight: $font-bold;
            color: $dark;
            @include media(down, $breakpoint-xl){
                line-height: size(70);
                padding: size(0) size(18);
            }
            .bx-caret-right {
                color: $primary;
                font-size: size(20);
                display: inline-block;
                position: relative;
                top: 3px;
                margin-right: size(10);
            }
        }
    }
}


.change-pass {
    .card-body {
        padding: size(0);
    }
    .change-pass-wrapper {
        max-width: 500px;
    }
    .btn {
        padding-left: 36px;
        padding-right: 36px;
        @include media(down, $breakpoint-xl){
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

.maintenance-accordion {
    .card {
        padding: 0;
        border: 0;

        .card-header {
            border-radius: 10px;
            border-bottom: 0;
        }

        .card-body {
            box-shadow: 0px 10px 20px 10px #f3f1f1;
            padding: 25px;
            border-radius: 0 0 10px 10px;
        }

        .btn {
            padding: 8px 0;
            color: $dark;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .accordion-group {
        &.panel-open {
            .card {
                .btn {
                    color: $primary;
                }

                .card-header {
                    border-radius: 10px 10px 0 0;

                    &::before {
                        transform: rotate(-90deg);
                    }
                }
            }
        }

        .card {
            .card-header {
                position: relative;

                &::before {
                    content: "\ec8f";
                    font-family: "boxicons" !important;
                    position: absolute;
                    top: 22px;
                    right: 20px;
                    transform: rotate(90deg);
                    font-size: 18px;
                    color: $gray;
                }
            }
        }
    }
}